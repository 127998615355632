import { useScreenSizeContext, useSiteSettingContext } from '@/pages/_app';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import SearchIcon from '@/shared/icons/search-icon.svg';
import { SearchPageAttribute } from '@/shared/lib-api';
import { useApi } from '@/shared/util/api';
import { Colors } from '@/shared/util/colors';
import { LoadingState } from '@/shared/util/general-helpers';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import { slugifyOptions } from '@/web/util/helpers/url-helpers';
import { Box, Button, Container } from '@mui/material';
import dynamic from 'next/dynamic';
import Image from '@/web/views/components/image/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import slugify from 'slugify';
import {
  SearchInput,
  brandSynonyms,
} from '../default-components/input/search/search-input';
import { TopBarSignUpButton } from '../top-bar/sign-up-button';
import { TopBarBurgerMenu } from '../top-bar/top-bar-burger-menu';
import { TopBarButton } from '../top-bar/top-bar-button';
interface SRPTopBarProps {
  hideSearch?: boolean;
  bgcolor?: string;
  isSRP?: boolean;
}

const LoginModal = dynamic(
  () => import('@/web/views/components/login-modal/login-modal'),
  { ssr: false },
);
export const SRPTopBar = (props: SRPTopBarProps) => {
  const screenSize = useScreenSizeContext();
  return screenSize.isMobile ? (
    <SRPTopBarMobile {...props} />
  ) : (
    <SRPTopBarDesktop {...props} />
  );
};

export default SRPTopBar;

const SRPTopBarMobile = (props: SRPTopBarProps) => {
  const store = useStore();
  const { logo, name } = useSiteSettingContext();
  return (
    <Box>
      <Box
        alignItems={'center'}
        height={70}
        width={'100%'}
        bgcolor={props.bgcolor ?? Colors.LightBlue}
        paddingX={'8px'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Box display="flex" marginRight={2}>
            <TopBarBurgerMenu anchor="left" iconSize={23} />
          </Box>
          <Link href={'/'}>
            <Box
              sx={cursorPointer}
              alignItems={'center'}
              display={'flex'}
              flexDirection={'row'}>
              <Image
                loading="eager"
                src={logo.src}
                alt={name}
                width={136}
                height={20}
              />
            </Box>
          </Link>
        </Box>

        <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
          <TopBarButton />
          <PersistGate persistor={(store as any).__persistor}>
            <LoginModal />
          </PersistGate>
        </Box>
      </Box>
    </Box>
  );
};

const SRPTopBarDesktop = (props: SRPTopBarProps) => {
  const store = useStore();
  const { logo, name } = useSiteSettingContext();
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      height={70}
      width={'100%'}
      bgcolor={props.bgcolor ?? Colors.LightBlue}>
      <Container>
        <Box
          paddingX={'8px'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}>
          <Link href={'/'}>
            <Box
              sx={cursorPointer}
              alignItems={'center'}
              display={'flex'}
              flexDirection={'row'}>
              <Image
                loading="eager"
                src={logo.src}
                alt={name}
                width={149}
                height={45}
              />
            </Box>
          </Link>

          {!props.hideSearch && (
            <Box>
              <SearchBarTopBar isSRP={props.isSRP} />
            </Box>
          )}
          <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
            <TopBarButton />
            <TopBarSignUpButton />
            <PersistGate persistor={(store as any).__persistor}>
              <LoginModal />
            </PersistGate>
            <TopBarBurgerMenu />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export const SearchBarTopBar = (props: { isSRP?: boolean }) => {
  return (
    <Box
      bgcolor={Colors.DarkerBlue}
      position="relative"
      padding={1}
      borderRadius={'100px'}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}>
      <SearchBarPlain {...props} topbar={true} />
    </Box>
  );
};

export const SearchBar = (props: { isSRP?: boolean }) => {
  return (
    <>
      <Box
        bgcolor={Colors.White}
        border={'1px solid #D9D9D9'}
        position="relative"
        padding={1}
        marginBottom={2}
        borderRadius={'4px'}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}>
        <SearchBarPlain {...props} topbar={false} />
      </Box>
    </>
  );
};

export const SearchBarPlain = (props: { isSRP?: boolean; topbar: boolean }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [attributes, setAttributes] = useState<SearchPageAttribute[]>([]);
  const [attributeValues, setAttributeValues] = useState<string[]>([]);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const { searchApi } = useApi();
  const { handleRequest } = useRequestHelper();
  const router = useRouter();

  useEffect(() => {
    (async () => {
      if (attributes.length === 0 && hasInteracted) {
        let request = searchApi.apiSearchSearchbrandattributesGet();
        const [result, errorAndState] = await handleRequest(request);
        if (errorAndState.state === LoadingState.Success) {
          setAttributeValues(result.map(p => p.label));
          setAttributes(result);
        }
      }
    })();
  }, [handleRequest, setAttributeValues, attributes.length, hasInteracted]);

  useEffect(() => {
    (async () => {
      var synonyms = [];
      if (brandSynonyms.find(p => p.includes(searchValue)) !== undefined) {
        for (
          var i = 0;
          i < brandSynonyms.find(p => p.includes(searchValue)).length;
          i++
        ) {
          synonyms.push(
            slugify(
              brandSynonyms.find(p => p.includes(searchValue))[i],
              slugifyOptions,
            ),
          );
        }
      }
      let make = attributes.find(
        p =>
          slugify(p.value, slugifyOptions) ===
            slugify(searchValue, slugifyOptions) ||
          synonyms.includes(slugify(p.value, slugifyOptions)),
      );
      if (
        attributes.some(
          p =>
            slugify(p.value) === slugify(searchValue, slugifyOptions) ||
            synonyms.includes(slugify(searchValue, slugifyOptions)),
        ) &&
        attributeValues.filter(p =>
          slugify(p, slugifyOptions).includes(make.value),
        ).length === 1
      ) {
        let request = searchApi.apiSearchSearchmodelattributesGet({
          brand: make.label,
        });
        const [result, errorAndState] = await handleRequest(request);
        if (errorAndState.state === LoadingState.Success) {
          setAttributeValues(
            attributeValues.concat(result.map(p => make.label + ' ' + p.label)),
          );
          setAttributes(attributes.concat(result));
        }
      }
    })();
  }, [handleRequest, searchApi, searchValue, setAttributes]);

  const onSubmit = useCallback(
    async (value?: string) => {
      const val = value ?? searchValue;
      //map to new search + submit + go to srp?
      let make = attributes.find(
        p =>
          slugify(p.value, slugifyOptions) ===
            slugify(val.split(' ')[0], slugifyOptions) ||
          slugify(p.value, slugifyOptions) ===
            slugify(
              val.split(' ')[0] + ' ' + val.split(' ')[1],
              slugifyOptions,
            ),
      );
      if (!make) {
        router.push(`/s/alle-biler?soegning=${encodeURIComponent(val)}`);
        return;
      }
      const onlyMake = val.split(' ').length === 1;
      if (!onlyMake) {
        let modelval = slugify(val, slugifyOptions)
          .replace(make.value, '')
          .trim();
        let model = attributes.find(
          p =>
            slugify(p.value, slugifyOptions) ===
            slugify(modelval, slugifyOptions),
        );
        if (props.isSRP) {
          window.location.href = `/alle-biler/${make.value}/${
            model?.value ?? slugify(modelval, slugifyOptions)
          }`;
        } else {
          router.push(
            `/alle-biler/${make.value}/${
              model?.value ?? slugify(modelval, slugifyOptions)
            }`,
          );
        }
      } else {
        router.push(`/alle-biler/${make.value}`);
      }
    },
    [attributes, searchValue, router, props.isSRP],
  );

  const onInputChanged = useCallback(
    (data: React.SyntheticEvent<Element, Event>, value: string) => {
      if (value === undefined) {
        setSearchValue(''); //freesolo, hvis skrevet værdi ikke er i options
      }

      if (data != null && value !== undefined) {
        setSearchValue(value);
      }
      if (data != null && (data.type === 'click' || data.type === 'keydown')) {
        onSubmit(value);
      }
    },
    [setSearchValue, onSubmit],
  );

  const onEnter = useCallback(
    (data: React.KeyboardEvent<HTMLDivElement>) => {
      if (data != null && data.type === 'keydown' && data.code === 'Enter') {
        onSubmit();
      }
    },
    [onSubmit],
  );

  const onFocus = useCallback(() => {
    setHasInteracted(true);
  }, []);
  return (
    <>
      <SearchInput
        topbar={props.topbar}
        attributes={attributeValues}
        value={searchValue}
        onFocus={onFocus}
        onChange={onInputChanged}
        onEnter={onEnter}
      />
      {props.topbar ? (
        <Box
          paddingRight={2}
          display={'flex'}
          style={{ cursor: 'pointer' }}
          onClick={() => onSubmit()}
          alignItems={'center'}>
          <Image alt="Søg" src={SearchIcon.src} width={24} height={24} />
        </Box>
      ) : (
        <Button
          onClick={() => onSubmit()}
          color="secondary"
          style={{
            height: 47,
            position: 'absolute',
            right: 0,
            boxShadow: 'none',
          }}>
          Søg
        </Button>
      )}
    </>
  );
};
