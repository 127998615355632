import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FilterOptionsState,
  PopperProps,
  styled,
  TextField,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultInputError from '../defaults/default-input-error';
import { Colors } from '@/shared/util/colors';

const StyledPopper = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 101,
  right: '0px',
  top: '50px',
  borderRadius: 4,
  width: '100%',
  borderWidth: '0px',
  borderStyle: 'solid',
  borderColor: '#D9D9D9',
  marginTop: theme.spacing(1),
  ['& ul']: {
    borderRadius: 4,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#D9D9D9',
    padding: '0px',
  },
  ['& .MuiPaper-root']: {
    borderRadius: 4,
  },
  ['& li:nth-child(even)']: {
    backgroundColor: Colors.Background.Default,
  },
}));

interface PropsFromParent {
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => void;
  onEnter: (data: React.KeyboardEvent<HTMLDivElement>) => void;
  onFocus: () => void;
  value: string;
  attributes: string[];
  error?: string;
  topbar: boolean;
}

export const brandSynonyms = [['vw', 'volkswagen']];

export function SearchInput(props: PropsFromParent) {
  const selectItems = useMemo(() => {
    let result = [...props.attributes];
    return result;
  }, [props.attributes]);
  const [open, setOpen] = useState<boolean>(false);
  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const generatePopperComponent = useCallback(
    (
      popperProps: PopperProps & {
        children?: React.ReactNode;
      },
    ) => {
      return (
        popperProps.open && <StyledPopper>{popperProps.children}</StyledPopper>
      );
    },
    [],
  );

  return (
    <>
      <Autocomplete
        style={{
          border: 'none',
          borderRadius: '2px',
          height: 31,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        disableClearable
        noOptionsText={'Ingen'}
        value={props.value}
        open={open && selectItems.length !== 0}
        onFocus={props.onFocus}
        onOpen={onOpen}
        onClose={onClose}
        onChange={props.onChange}
        onKeyDown={props.onEnter}
        filterOptions={filterOptionsWithSynonyms()}
        selectOnFocus
        freeSolo={
          props.value === undefined || props.value === '' ? false : true
        }
        PopperComponent={generatePopperComponent}
        onInputChange={props.onChange}
        options={selectItems}
        renderInput={params => (
          <GenerateTextInput {...params} topbar={props.topbar} />
        )}
      />
      <DefaultInputError error={props.error} />
    </>
  );
}
const GenerateTextInput = (
  props: AutocompleteRenderInputParams & { topbar: boolean },
) => {
  const { t } = useTranslation();

  const inputStyle = useMemo(
    () => ({
      color: Colors.White,
      paddingLeft: 2,
      minWidth: 275,
      paddingRight: 2,
    }),
    [],
  );

  const InputProp = useMemo(
    () => ({
      ...props.InputProps,
      endAdornment: <></>,
    }),
    [props.InputProps],
  );
  return (
    <TextField
      inputProps={{
        ...props.inputProps,
        className: '',
        style: {
          color: props.topbar ? 'white' : 'initial',
          fontSize: props.topbar ? 'initial' : 15,
        },
      }}
      fullWidth={true}
      InputProps={InputProp}
      style={inputStyle}
      placeholder={
        props.topbar
          ? t('SRP.SearchBarInputPlaceHolder')
          : t('SRP.SearchBarInputPlaceHolder2')
      }
    />
  );
};
function filterOptionsWithSynonyms() {
  return (items: string[], state: FilterOptionsState<string>) => {
    let brandsToInclude = [];
    for (var i = 0; i < brandSynonyms.length; i++) {
      for (var j = 0; j < brandSynonyms[i].length; j++) {
        if (
          brandSynonyms[i][j]
            .toLowerCase()
            .includes(state.inputValue.toLowerCase()) ||
          state.inputValue.toLowerCase().includes(brandSynonyms[i][j])
        ) {
          brandsToInclude = brandsToInclude.concat(brandSynonyms[i]);
          break;
        }
      }
    }
    return items.filter(
      p =>
        p.toLowerCase().includes(state.inputValue.toLowerCase()) ||
        brandsToInclude.includes(p.toLowerCase()) ||
        (brandsToInclude.filter(t => p.split(' ')[0].toLowerCase().includes(t))
          .length !== 0 &&
          p.split(' ').length > 1 &&
          state.inputValue.split(' ').length > 1 &&
          p
            .split(' ')[1]
            .toLowerCase()
            .includes(state.inputValue.split(' ')[1].toLowerCase())),
    );
  };
}
