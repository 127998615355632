/* eslint-disable @next/next/no-img-element */
import exclamation from '@/shared/icons/circle-exclamation.svg';
import { Colors } from '@/shared/util/colors';
import { Box } from '@mui/material';
import * as React from 'react';

export interface DefaultInputErrorProps {
  error: string;
}

function DefaultInputError(props: DefaultInputErrorProps) {
  return (
    <>
      {props.error && (
        <Box display="flex" marginTop={0.3} fontSize={12} color={Colors.Error}>
          <img
            alt="Fejl"
            style={{
              verticalAlign: 'middle',
            }}
            src={exclamation.src}
          />
          <Box
            style={{
              verticalAlign: 'middle',
              marginLeft: '6.42px',
              whiteSpace: 'nowrap',
            }}>
            {props.error}
          </Box>
        </Box>
      )}
    </>
  );
}

export default DefaultInputError;
