import { Badge, Box, styled, Tooltip } from '@mui/material';
import { default as React } from 'react';
import heartIcon from '@/shared/icons/top-bar/search.svg';
import { useAppSelector } from '@/shared/store/hooks';
import Image from '@/web/views/components/image/image';
import { useScreenSizeContext } from '@/pages/_app';

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: '#1066B6',
  },
});

export const SearchAgentBarButton = () => {
  const { isMobile } = useScreenSizeContext();
  const searchAgents = useAppSelector(state => state.auth.searchAgents);
  return (
    <Tooltip title={isMobile ? '' : 'Gemte søgninger'}>
      <Box
        paddingLeft={0}
        textAlign={isMobile ? 'center' : 'initial'}
        width={'40px'}>
        <StyledBadge
          badgeContent={searchAgents.length}
          invisible={(searchAgents.length ?? 0) === 0}>
          <Image
            width={23}
            height={23}
            src={heartIcon.src}
            alt={'Gemte søgninger'}
          />
        </StyledBadge>
      </Box>
    </Tooltip>
  );
};
