import { Box, styled, Typography } from '@mui/material';
import { default as React, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/shared/store/hooks';
import { FavBarButton } from './fav-bar-button';
import { LoginBarButton } from './login-bar-button';
import { useRouter } from 'next/router';
import { useScreenSizeContext } from '@/pages/_app';
import { SearchAgentBarButton } from './searchAgent-bar-button';
import Link from 'next/link';
import { ProfileBarButton } from './profile-bar-button';
import { useLoginOrCreatedEvent } from '@/shared/hooks/login-hook';
import { Colors } from '@/shared/util/colors';

const StyledDiv = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
}));

interface TopBarButtonProps {}

export const TopBarButton = (props: TopBarButtonProps) => {
  const router = useRouter();
  const isLoggedIn = useAppSelector(state => state.auth.loginToken);
  const screenSize = useScreenSizeContext();
  const { t } = useTranslation();
  const handleProfile = useCallback(() => {
    if (isLoggedIn) {
      router.push('/min-side/indstillinger');
    }
  }, [isLoggedIn, router]);

  const handleFav = useCallback(() => {
    if (isLoggedIn) {
      router.push('/min-side/favoritter');
    }
  }, [isLoggedIn, router]);
  const { loginOrCreate: onFav } = useLoginOrCreatedEvent(handleFav);
  const { loginOrCreate: onPro } = useLoginOrCreatedEvent(handleProfile);

  const goToMobileSearchBar = useCallback(() => {
    router.push('/min-side/soeg');
  }, [router]);

  const element = isLoggedIn ? (
    <>
      <StyledDiv>
        {!screenSize.isMobile && (
          <Link href={'/saelg-bil'}>
            <Typography
              color={Colors.White}
              fontSize={15}
              paddingRight={3}
              paddingLeft={1}>
              {t('Auth.SellFree')}
            </Typography>
          </Link>
        )}
        <Link
          href={
            screenSize.isMobile
              ? '/min-side/soeg'
              : '/min-side/gemte-soegninger'
          }>
          <SearchAgentBarButton />
        </Link>
        <Link href={'/min-side/favoritter'}>
          <FavBarButton />
        </Link>
        {screenSize.isMobile && (
          <Link href={'/min-side/indstillinger'}>
            <ProfileBarButton />
          </Link>
        )}
      </StyledDiv>
    </>
  ) : (
    <StyledDiv>
      {screenSize.isMobile && (
        <Box onClick={goToMobileSearchBar}>
          <SearchAgentBarButton />
        </Box>
      )}
      {!screenSize.isMobile && (
        <Link href={'/saelg-bil'}>
          <Typography
            color={Colors.White}
            fontSize={15}
            paddingRight={3}
            paddingLeft={1}>
            {t('Auth.SellFree')}
          </Typography>
        </Link>
      )}
      <Box display="flex">
        <LoginBarButton onFav={onFav} onPro={onPro} />
      </Box>
    </StyledDiv>
  );

  return element;
};
