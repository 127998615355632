import { Badge, Box, Tooltip } from '@mui/material';
import { default as React } from 'react';
import heartIcon from '@/shared/icons/top-bar/profile.svg';
import Image from '@/web/views/components/image/image';
import { useScreenSizeContext } from '@/pages/_app';

export const ProfileBarButton = () => {
  const { isMobile } = useScreenSizeContext();
  return (
    <Tooltip title={isMobile ? '' : 'Indstillinger'}>
      <Box
        paddingLeft={0}
        textAlign={isMobile ? 'center' : 'initial'}
        width={'40px'}>
        <Badge color="error" badgeContent={0} invisible={true}>
          <Image
            width={23}
            height={23}
            src={heartIcon.src}
            alt={'Indstillinger'}
          />
        </Badge>
      </Box>
    </Tooltip>
  );
};
