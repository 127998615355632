import { useAppDispatch, useAppSelector } from '@/shared/store/hooks';
import { Colors } from '@/shared/util/colors';
import { Box, styled, Typography } from '@mui/material';

import { default as React, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DownArrow from '@/shared/icons/down-arrow-white.svg';
import DropdownMenu, {
  DropdownMenuItem,
} from '../default-components/dropdown-menu/dropdown-menu';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import { useRouter } from 'next/router';
import { setShouldLogout } from '@/shared/store/reducers/AuthSlice';
import { useLoginContext } from '@/pages/_app';
import { UserProfileType } from '@/shared/lib-api';

const SignInButton = styled(Box)(({ theme }) => ({
  borderradius: '60px !important',
  backgroundColor: Colors.DarkerBlue + ' !important',
  borderRadius: '30px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '0px !important',
  paddingBottom: '0px !important',
  minWidth: 25,
  minHeight: 25,
  overflow: 'hidden',
}));

const menuStyle = {
  boxShadow: 'rgb(0 0 0 / 25%) 0px 4px 24px !important',
  borderRadius: '8px !important',
};
export const TopBarSignUpButton: React.FC = props => {
  const { t } = useTranslation();
  const router = useRouter();
  const isLoggedIn = useAppSelector(state => state.auth.loginToken);
  const loginContext = useLoginContext();
  const dispatch = useAppDispatch();
  const profileType = useAppSelector(
    state => state.auth.cachedUser?.profileType,
  );

  const menuItems = useMemo<DropdownMenuItem[]>(() => {
    const items: DropdownMenuItem[] = [];

    items.push(
      {
        text: t('MyBilhandel.HeartPrimaryIcon'),
        onClick: () => {
          router.push('/min-side/favoritter', undefined, {});
        },
      },
      {
        text: t('MyBilhandel.SearchIcon'),
        onClick: () => {
          router.push('/min-side/gemte-soegninger');
        },
      },
      {
        text: t('MyBilhandel.MyAdsIcon'),
        onClick: () => {
          router.push('/min-side/annoncer');
        },
      },
    );
    if (profileType === UserProfileType.Private) {
      items.push({
        text: t('MyBilhandel.SettingsIcon'),
        onClick: () => {
          router.push('/min-side/indstillinger');
        },
      });
    }
    items.push({
      text: t('Auth.Sell'),
      onClick: () => {
        router.push('/saelg-bil');
      },
    });
    if (isLoggedIn) {
      items.push({
        text: t('MyBilhandel.LogOutIcon'),
        onClick: () => {
          dispatch(setShouldLogout());
          router.push('/');
        },
      });
    }

    return items;
  }, [dispatch, router, t, isLoggedIn, profileType]);

  const handleClick = useCallback(() => {
    if (isLoggedIn) {
      router.push('/min-side/favoritter');
    } else {
      loginContext.open();
    }
  }, [isLoggedIn, router, loginContext]);

  return (
    <SignInButton>
      <Box
        onClick={handleClick}
        sx={cursorPointer}
        borderRight={'2px solid rgba(0, 0, 0, 0.15)'}>
        <Typography
          height={45}
          lineHeight={'45px'}
          textAlign={'center'}
          fontWeight={700}
          paddingLeft={1.5}
          paddingRight={1.5}
          width={135}
          color={Colors.White}>
          {!isLoggedIn ? t('Auth.CreateUser') : t('Auth.MyBilhandel')}
        </Typography>
      </Box>
      <Box sx={cursorPointer}>
        <DropdownMenu
          menuSx={menuStyle}
          src={DownArrow.src}
          menuItems={menuItems}
        />
      </Box>
    </SignInButton>
  );
};
