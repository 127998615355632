import { Box, SwipeableDrawer, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@/shared/icons/top-bar/navigation-menu.svg';
import React, { useCallback } from 'react';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import { Colors } from '@/shared/util/colors';
import Link from 'next/link';
import Image from '@/web/views/components/image/image';
import { useAppSelector } from '@/shared/store/hooks';
import { useLoginContext, useSiteSettingContext } from '@/pages/_app';
import { useRouter } from 'next/router';
import { useAuth } from '@/shared/hooks/auth-hook';
import { useTranslation } from 'react-i18next';

export const MenuLink = (props: {
  text: string;
  href?: string;
  onClick?: () => void;
  inset?: boolean;
}) => {
  return (
    <Box marginLeft={props.inset ? 2 : 0}>
      {props.href && (
        <Link href={props.href}>
          <Typography
            color={Colors.White}
            fontWeight={props.inset ? 500 : 700}
            fontSize={props.inset ? 20 : 22}
            lineHeight={props.inset ? '170%' : '200%'}>
            {props.text}
          </Typography>
        </Link>
      )}

      {props.onClick && (
        <a style={{ cursor: 'pointer' }} onClick={props.onClick}>
          <Typography
            color={Colors.White}
            fontWeight={props.inset ? 500 : 700}
            fontSize={props.inset ? 20 : 22}
            lineHeight={props.inset ? '170%' : '200%'}>
            {props.text}
          </Typography>
        </a>
      )}
    </Box>
  );
};

export const TopBarBurgerMenu = (props: {
  anchor?: 'top' | 'right' | 'bottom' | 'left';
  iconSize?: number;
}) => {
  const isLoggedIn = useAppSelector(state => state.auth.loginToken);
  const loginContext = useLoginContext();
  const siteSettings = useSiteSettingContext();
  const { setLogoutAfterRedirect } = useAuth();
  const router = useRouter();
  const { t } = useTranslation();

  const [openState, setOpenState] = React.useState<boolean>(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpenState(open);
    };

  const onClickClose = () => {
    setOpenState(false);
  };

  const onClickLogin = () => {
    loginContext.open();
  };

  const onClickLogOut = useCallback(() => {
    setLogoutAfterRedirect();
    router.push('/');
  }, [setLogoutAfterRedirect, router]);

  const list = () => (
    <Box sx={{ width: 'auto' }}>
      <Box display={'flex'} margin={3}>
        <Box
          marginLeft={'auto'}
          onClick={() => onClickClose()}
          sx={cursorPointer}>
          <CloseIcon />
        </Box>
      </Box>
      <Box
        marginX={7}
        marginY={7}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}>
        <MenuLink
          text={t('Auth.MyBilhandel')}
          href={isLoggedIn && '/min-side/favoritter'}
          onClick={!isLoggedIn && onClickLogin}
        />
        {isLoggedIn && (
          <>
            <MenuLink
              text={t('MyBilhandel.HeartPrimaryIcon')}
              inset={true}
              href="/min-side/favoritter"
            />
            <MenuLink
              text={t('MyBilhandel.SearchIcon')}
              inset={true}
              href="/min-side/gemte-soegninger"
            />
            <MenuLink
              text={t('MyBilhandel.MyAdsIcon')}
              inset={true}
              href="/min-side/annoncer"
            />
            <MenuLink
              text={t('MyBilhandel.SettingsIcon')}
              inset={true}
              href="/min-side/indstillinger"
            />
            <MenuLink
              text={t('MyBilhandel.LogOutIcon')}
              inset={true}
              onClick={onClickLogOut}
            />
          </>
        )}
        {!isLoggedIn && (
          <>
            <MenuLink text={'Sælg din bil'} inset={true} href="/saelg-bil" />
            <MenuLink
              text={t('Auth.SignIn')}
              inset={true}
              onClick={onClickLogin}
            />
            <MenuLink
              text={t('Auth.CreateUser')}
              inset={true}
              onClick={onClickLogin}
            />
          </>
        )}
        <MenuLink text={'Sælg din bil'} href="/saelg-bil" />
        {siteSettings.name == 'Bilhandel' && (
          <MenuLink text={'Guide til bilkøb'} href="/guide" />
        )}
        <MenuLink text={'Find forhandler'} href="/find-forhandler" />
        <MenuLink text={'Support'} href="/faq" />
        <MenuLink text={'Annoncering'} href="https://pace.dk" />
        <MenuLink
          text={'Forhandlersupport'}
          href="mailto:support@bilhandel.dk"
        />
        <MenuLink
          text={'Vilkår'}
          href="https://heymate.dk/vilkaar-og-privatlivspolitik"
        />
        {siteSettings.name == 'Bilhandel' && (
          <MenuLink text={'Om os'} href="/om" />
        )}
        {siteSettings.name == 'Bilhandel' && (
          <MenuLink text={'Bilviden'} href="/bilviden" />
        )}
      </Box>
    </Box>
  );

  return (
    <div>
      <Box>
        <Box
          onClick={toggleDrawer(true)}
          sx={cursorPointer}
          marginLeft={1}
          marginTop={1}>
          <Image
            alt="Menu"
            src={MenuIcon.src}
            width={props.iconSize ?? 24}
            height={props.iconSize ?? 24}
          />
        </Box>
        <SwipeableDrawer
          PaperProps={{
            sx: {
              backgroundColor: Colors.DarkBlue,
              color: Colors.White,
            },
          }}
          anchor={props.anchor ?? 'right'}
          open={openState}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}>
          {list()}
        </SwipeableDrawer>
      </Box>
    </div>
  );
};
