import { Badge, Box, Tooltip } from '@mui/material';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import heartIcon from '@/shared/icons/top-bar/hearth.svg';
import { useAppSelector } from '@/shared/store/hooks';
import Image from '@/web/views/components/image/image';
import { useScreenSizeContext } from '@/pages/_app';

export const FavBarButton = () => {
  const { t } = useTranslation();
  const favouriteCars = useAppSelector(state => state.auth.favouriteCars);
  const { isMobile } = useScreenSizeContext();
  return (
    <Tooltip title={isMobile ? '' : t('Auth.Favorites')}>
      <Box
        textAlign={isMobile ? 'center' : 'initial'}
        paddingLeft={0}
        width={'40px'}>
        <Badge
          color="error"
          badgeContent={favouriteCars.length}
          invisible={(favouriteCars.length ?? 0) === 0}>
          <Image
            width={23}
            height={23}
            src={heartIcon.src}
            alt={t('Auth.Favorites')}
          />
        </Badge>
      </Box>
    </Tooltip>
  );
};
