import { Colors } from '@/shared/util/colors';
import { setHtml } from '@/shared/util/helpers/general-helper';
import { Box, Typography } from '@mui/material';
import Image from '@/web/views/components/image/image';
import React, { FC, ForwardedRef } from 'react';

interface PropsFromParent {
  text: string;
  marginTop?: string;
  marginLeft?: string;
  textcolor?: string;
  icon?: string;
  iconHeight?: number;
  fontSize?: number;
  lineHeight?: number;
  iconWidth?: number;
  fontWeight?: number;
}

const TextWithIcon: React.FC<PropsFromParent> = React.forwardRef(
  (props: PropsFromParent, ref: ForwardedRef<HTMLImageElement>) => {
    var { iconHeight, iconWidth, lineHeight, ...filteredProps } = props;
    return (
      <Box
        marginTop={props.marginTop}
        minHeight={25}
        alignItems={'center'}
        display={'flex'}
        flexDirection={'row'}>
        {props.icon && (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            {...filteredProps}
            ref={ref}
            alt="icon"
            src={props.icon}
            height={props.iconHeight ? props.iconHeight : 24}
            width={props.iconWidth ? props.iconWidth : 24}
          />
        )}
        <Typography
          fontWeight={props.fontWeight ? props.fontWeight : 'initial'}
          variant={'body1'}
          component="span"
          marginLeft={props.marginLeft ? props.marginLeft : 1}
          color={props.textcolor ?? Colors.Primary}
          fontSize={props.fontSize ?? 15}
          lineHeight={props.lineHeight ?? 1}
          dangerouslySetInnerHTML={setHtml(props.text + '')}
          sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
        />
      </Box>
    );
  },
);

TextWithIcon.displayName = 'TextWithIcon';
export default TextWithIcon;
