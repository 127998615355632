import { useCallback, useEffect, useState } from 'react';
import { useAuth } from './auth-hook';
import { useLoginContext } from '@/pages/_app';

export function useLoginOrCreatedEvent(onLoginInOrCreated?: () => void) {
  const { isLoggedIn } = useAuth();
  const loginContext = useLoginContext();
  const [raiseEventAfterLoginOrCreated, setRaiseEventAfterLoginOrCreated] =
    useState<boolean>(false);
  const raiseEvent = useCallback(() => {
    if (onLoginInOrCreated) {
      onLoginInOrCreated();
    }
  }, [onLoginInOrCreated]);

  const loginOrCreate = useCallback(() => {
    if (!isLoggedIn) {
      setRaiseEventAfterLoginOrCreated(true);
      loginContext.open();
    } else {
      onLoginInOrCreated();
    }
  }, [isLoggedIn, onLoginInOrCreated, loginContext]);

  useEffect(() => {
    if (isLoggedIn && raiseEventAfterLoginOrCreated) {
      raiseEvent();
      setRaiseEventAfterLoginOrCreated(false);
    }
  }, [isLoggedIn, raiseEvent, raiseEventAfterLoginOrCreated]);

  return {
    loginOrCreate,
  };
}
