import { Colors } from '@/shared/util/colors';
import {
  Fade,
  Menu,
  MenuItem,
  PopoverOrigin,
  Typography,
  Box,
} from '@mui/material';
import React, {
  FC,
  useMemo,
  useState,
  useCallback,
  MouseEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import downArrowWhiteSpace from '@/shared/icons/down-arrow-white.svg';
import Image from '@/web/views/components/image/image';

export interface DropdownPropsFromParent {
  text?: string;
  fontSize?: string | number;
  src?: string;
  justifyContent?: 'space-between';
  fontWeight?: 'bold' | 400;
  color?: string;
  onClick?: () => void;
  menuItems: DropdownMenuItem[];
  marginLeft?: number;
  marginRight?: number;
  menuSx?: React.CSSProperties;
}
export interface DropdownMenuItem {
  text: string;
  subText?: string;
  onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

const origin: PopoverOrigin | undefined = {
  vertical: 'bottom',
  horizontal: 'left',
};
const transformOrigin: PopoverOrigin | undefined = {
  vertical: -20,
  horizontal: 150,
};

const generateMenuItems =
  // eslint-disable-next-line react/display-name
  (close: () => void) => (menu: DropdownMenuItem, index: number) =>
    (
      <MenuItem
        sx={MenuItemHeight}
        key={menu.text}
        onClick={generateOnClick(menu, close)}>
        {menu.subText ? (
          <Box width={'100%'} paddingTop={0.5}>
            <Typography whiteSpace={'initial'} fontSize={'20px'} variant={'h4'}>
              {menu.text}
            </Typography>
            <Typography whiteSpace={'initial'} color={'muted.main'}>
              {menu.subText}
            </Typography>
          </Box>
        ) : (
          menu.text
        )}
      </MenuItem>
    );

const MenuItemHeight = {
  height: 40,
};

function generateOnClick(
  menu: {
    text: string;
    onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  },
  close: () => void,
): React.MouseEventHandler<HTMLLIElement> | undefined {
  return (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    menu.onClick(event);
    close();
  };
}

const DropdownMenu: FC<DropdownPropsFromParent> = props => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleMenuChange = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);

      if (props.onClick) {
        props.onClick();
      }
    },
    [props, setAnchorEl],
  );
  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);
  return (
    <>
      <Box
        onClick={handleMenuChange}
        alignItems="center"
        height={'100%'}
        paddingX={2}
        justifyContent={props.justifyContent ? props.justifyContent : undefined}
        display="flex"
        flexDirection="row">
        {props.text && (
          <Box>
            <Typography
              color={props.color ? props.color : Colors.DefaultFontColor}
              fontSize={props.fontSize}
              fontWeight={props.fontWeight ? props.fontWeight : 'bold'}>
              {t(props.text)}
            </Typography>
          </Box>
        )}
        <Box marginLeft={props.marginLeft} marginRight={props.marginRight}>
          <Image
            width={14}
            height={8}
            src={props.src ? props.src : downArrowWhiteSpace.src}
            alt="downArrowWhiteSpace"
          />
        </Box>
      </Box>
      {props.menuItems && props.menuItems.length > 0 && (
        <Menu
          PaperProps={generatePaperProps(props)}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={origin}
          transformOrigin={transformOrigin}>
          {props.menuItems.map(generateMenuItems(handleClose))}
        </Menu>
      )}
    </>
  );
};
function generatePaperProps(props: DropdownPropsFromParent) {
  return { sx: props.menuSx };
}

export default DropdownMenu;
