import { Box, styled, Typography } from '@mui/material';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import lockIcon from '@/shared/icons/lock-icon.svg';
import { Colors } from '@/shared/util/colors';
import Image from '@/web/views/components/image/image';
import { useScreenSizeContext } from '@/pages/_app';
import { FavBarButton } from './fav-bar-button';
import { ProfileBarButton } from './profile-bar-button';

const LoginTextStyle = styled(Typography)(({ theme }) => ({
  color: Colors.White,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(3),
  fontSize: 15,
}));

interface LoginBarButtonProps {
  onFav: () => void;
  onPro: () => void;
}

export const LoginBarButton = (props: LoginBarButtonProps) => {
  const { t } = useTranslation();
  const screenSize = useScreenSizeContext();

  return !screenSize.isMobile ? (
    <Box display="flex" onClick={props.onFav}>
      <Image width={23} height={19.87} src={lockIcon.src} alt={'lockIcon'} />
      <LoginTextStyle>{t('Auth.SignIn')}</LoginTextStyle>
    </Box>
  ) : (
    <>
      <Box onClick={props.onFav}>
        <FavBarButton />
      </Box>
      <Box onClick={props.onPro}>
        <ProfileBarButton />
      </Box>
    </>
  );
};
